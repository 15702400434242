import { connect } from "react-redux";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";

import { addFileObject } from "app/helpers/commonInterface";
import {
  APPROVERD,
  ASSIGNED,
  PAUSED,
  REJECTED,
  SENT_FOR_CHECK,
} from "app/helpers/constants";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import { getStatus, getStatusArr } from "app/helpers/helperFunction";
import { statusChangeData } from "../AdminTaskDetailPage";
import SentForCheckPopup from "./SentForCheckPopup";
import {
  taskAddForChecking,
  updateTaskStatus,
} from "app/reducers/task/taskAction";
import { useAuth } from "app/components/pages/auth";
import PausedStatusModal from "app/components/pages/task/component/PausedStatusModal";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { taskData } from "app/reducers/task/taskDataInterface";

interface props {
  taskDetails: taskData;
  updateTaskStatusDispatch: Function;
  taskAddForCheckingDispatch: Function;
}

export interface sentForCheckDataModal {
  id?: number;
  status?: number;
  reviewerId: string;
  reviewMessage: string;
  fileObject: addFileObject;
}

const TaskDetailHeader: FC<props> = ({
  taskAddForCheckingDispatch,
  updateTaskStatusDispatch,
  taskDetails,
}) => {
  const params = useParams();
  const { currentUser } = useAuth();
  const [sentChekPopup, setSentCheckPopup] = useState({
    taskCheck: false,
    approve: false,
    rejected: false,
  });
  const [loading, setLoading] = useState(false);
  const [showPausedPopup, setShowPausedPopup] = useState({
    show: false,
    apiData: "",
  });
  // const {timerState, timerClick} = useTrackingTimer();

  const statusChangeApiDispatch = async (e: any, startDate?: Date) => {
    setLoading(true);
    await updateTaskStatusDispatch(
      {
        taskIds: [Number(params.id)],
        status: Number(e.target.dataset.id),
        startDate: startDate == undefined ? null : startDate,
      },
      Number(e.target.dataset.id),
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  const onChangeStatusHandler = async (e: any) => {
    if (Number(e.target.dataset.id) === PAUSED.id) {
      setShowPausedPopup({ show: true, apiData: e });
    } else {
      statusChangeApiDispatch(e);
    }
  };

  return (
    <div className="card-header pt-5 border-0 align-items-start m-0">
      <div className="card-title flex-column">
        <div className="fw-bold d-flex gap-2">
          <div>Task Details</div>
          {taskDetails.IsOverdue && (
            <div className="d-flex align-items-center text-gray-500 fs-7">
              <ImageDisplay
                path="/media/task/overdue_flag.png"
                errorPath="/media/task/overdue_flag.png"
                altText="flag"
                className="w-15px"
              />
              <div className="text-danger w-100px">Overdue</div>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex flex-wrap gap-3 mb-3 align-items-start">
        <div className="d-flex  gap-3">
          {currentUser?.Id === taskDetails.AssigneeId && (
            <div className="">
              <button
                className="btn btn-sm btn-light btn-active-light-primary w-125px"
                onClick={() =>
                  setSentCheckPopup({ ...sentChekPopup, taskCheck: true })
                }
                disabled={taskDetails.StatusValue === APPROVERD.id}
              >
                Send for Check
              </button>

              <SentForCheckPopup
                assigneeId={taskDetails.AssigneeId}
                taskDetails={taskDetails}
                closeModal={() =>
                  setSentCheckPopup({ ...sentChekPopup, taskCheck: false })
                }
                showPopup={sentChekPopup.taskCheck}
                sentTaskForCheckingDispatch={(
                  sentData: sentForCheckDataModal
                ) =>
                  taskAddForCheckingDispatch({
                    id: params.id,
                    status: SENT_FOR_CHECK.id,
                    ...sentData,
                  })
                }
              />
            </div>
          )}
          {currentUser?.Id !== taskDetails.AssigneeId && (
            <>
              <div className="">
                <button
                  className="btn btn-sm btn-light btn-active-light-success"
                  onClick={() =>
                    setSentCheckPopup({ ...sentChekPopup, approve: true })
                  }
                  disabled={taskDetails.StatusValue === APPROVERD.id}
                >
                  Approve
                </button>

                <SentForCheckPopup
                  assigneeId={taskDetails.AssigneeId}
                  taskDetails={taskDetails}
                  closeModal={() =>
                    setSentCheckPopup({ ...sentChekPopup, approve: false })
                  }
                  showPopup={sentChekPopup.approve}
                  sentTaskForCheckingDispatch={(
                    sentData: sentForCheckDataModal
                  ) =>
                    taskAddForCheckingDispatch({
                      id: params.id,
                      status: ASSIGNED.id,
                      ...sentData,
                    })
                  }
                />
              </div>
              <div className="">
                <button
                  className="btn btn-sm btn-light btn-active-light-danger"
                  onClick={() =>
                    setSentCheckPopup({ ...sentChekPopup, rejected: true })
                  }
                >
                  Reject
                </button>

                <SentForCheckPopup
                  assigneeId={taskDetails.AssigneeId}
                  taskDetails={taskDetails}
                  closeModal={() =>
                    setSentCheckPopup({ ...sentChekPopup, rejected: false })
                  }
                  showPopup={sentChekPopup.rejected}
                  sentTaskForCheckingDispatch={(
                    sentData: sentForCheckDataModal
                  ) =>
                    taskAddForCheckingDispatch({
                      id: params.id,
                      status: REJECTED.id,
                      ...sentData,
                    })
                  }
                />
              </div>
            </>
          )}
        </div>

        <div>
          <DropDown
            placeholder="Status"
            options={getStatusArr(taskDetails.StatusValue)}
            className={`form-select form-select-sm form-select-white show text-start w-175px status-border-${
              taskDetails.StatusValue
            } ${loading ? "display-dropdown-loader" : ""}`}
            apiCallDispatch={onChangeStatusHandler}
            displayLoader={loading}
            currentValue={{
              value: getStatus(taskDetails.StatusValue)?.id,
              name: getStatus(taskDetails.StatusValue)?.name,
            }}
            defaultValue={{
              value: getStatus(taskDetails.StatusValue)?.id,
              name: getStatus(taskDetails.StatusValue)?.name,
            }}
          />
        </div>

        <PausedStatusModal
          setShowPausedPopup={setShowPausedPopup}
          showPausedPopup={showPausedPopup}
          updateFun={statusChangeApiDispatch}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTaskStatusDispatch: (
      data: statusChangeData,
      status: string,
      successCallback: Function,
      failureCallback: Function
    ) =>
      dispatch(
        updateTaskStatus({ data, status, successCallback, failureCallback })
      ),
    taskAddForCheckingDispatch: (sentData: sentForCheckDataModal) =>
      dispatch(taskAddForChecking(sentData)),
  };
};
export default connect(null, mapDispatchToProps)(TaskDetailHeader);
